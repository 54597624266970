import React, { useEffect } from "react";
import { Link } from "react-router-dom";

const Sidebar = ({ sidebarItem, sidebarOpen, closeSidebar }) => {
  // Disable scrolling when the sidebar is open on mobile
  useEffect(() => {
    if (sidebarOpen) {
      document.body.classList.add("overflow-hidden");
    } else {
      document.body.classList.remove("overflow-hidden");
    }
  }, [sidebarOpen]);

  return (
    <>
      <div className="relative">
        <aside
          id="sidebar-multi-level-sidebar"
          className={`fixed top-10 left-0 z-40 w-64 h-full bg-card  transition-transform ${
            sidebarOpen ? "translate-x-0" : "-translate-x-full"
          } sm:translate-x-0`}
          aria-label="Sidebar"
        >
          <div className="h-full px-3   overflow-y-auto">
            {/* Sidebar Content */}
            <ul className="space-y-1 font-medium ">
              {sidebarItem.map((item) => (
                <li className="flex items-center mt-1" key={item.id}>
                  <Link
                    to={item.link}
                    onClick={closeSidebar}
                    className="flex items-center gap-3 p-3 text-text rounded-lg hover:w-full hover:bg-background transition-colors duration-200"
                  >
                    <span className="text-xl text-text">
                      {item.logo}
                    </span>
                    <span className="text-sm font-medium">{item.name}</span>
                  </Link>
                </li>
              ))}
              {/* More sidebar items */}
            </ul>
          </div>
        </aside>
      </div>
    </>
  );
};

export default Sidebar;
