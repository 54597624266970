import React from "react";

const UserSetting = () => {
  return (
    <>
      <div>
        <h1>User Setting</h1>
      </div>
    </>
  );
};

export default UserSetting;
