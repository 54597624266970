import React from "react";

const AdminSetting = () => {
  return (
    <>
      <div>AdminSetting</div>
    </>
  );
};

export default AdminSetting;
