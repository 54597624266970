import React, { useState } from "react";
import { IoSearch } from "react-icons/io5";

const SearchBar = () => {
  const [searchText, setSearchText] = useState("");
  const [showCloseButton, setShowCloseButton] = useState(false);

  const handleSearchText = (e) => {
    setSearchText(e.target.value);
    setShowCloseButton(e.target.value.length > 0);
  };

  const handleSearchClear = () => {
    setSearchText("");
    setShowCloseButton(false);
  };

  const handleSearch = (e) => {
    e.preventDefault();
    // Handle search functionality here
  };

  return (
    <>
      <div className="flex items-center w-full sm:w-auto lg:w-2/5">
        <form className="flex w-full" onSubmit={handleSearch}>
          <label htmlFor="default-search" className="sr-only">
            Search
          </label>
          <div className="relative w-full">
            <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
              <IoSearch className="w-3 h-3 text-gray-500 dark:text-gray-400" />
            </div>
            <input
              type="search"
              id="default-search"
              name="defaultSearch"
              value={searchText}
              onChange={handleSearchText}
              className="block w-full p-2 pl-10 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-0 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:border-blue-500"
              placeholder="Search..."
              required
            />
            <button
              type="submit"
              className="absolute right-0 bottom-1 top-1 bg-blue-500 hover:bg-blue-700 text-white font-medium rounded-lg text-sm px-4 py-0 mr-2 dark:bg-blue-600 dark:hover:bg-blue-700"
            >
              Search
            </button>
            {showCloseButton && (
              <button
                type="button"
                onClick={handleSearchClear}
                className="absolute right-20 bottom-1 top-1 bg-red-500 hover:bg-red-700 text-white font-medium rounded-lg text-sm px-2 py-0 mr-2"
              >
                Clear
              </button>
            )}
          </div>
        </form>
      </div>
    </>
  );
};

export default SearchBar;
