import { configureStore } from "@reduxjs/toolkit";
import AuthReducer from "./reducers/auth";
import NotificationReducer from "./reducers/notification";
import UserManagementReducer from "./reducers/users";

const store = configureStore({
  reducer: {
    auth: AuthReducer,
    notifications: NotificationReducer,
    userManagement: UserManagementReducer,
  }
});
export default store;
