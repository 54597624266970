// reducers/userSlice.js
import { createSlice } from "@reduxjs/toolkit";
import { adminGetAllUsers } from "../actions/users";

const DEFAULT_USER_STATE = {
  loading: false,
  userList: [],
  userSellerList: [],
  currentUser: {},
  totalCount: 0,
  sellerCount: 0,
};

export const userSlice = createSlice({
  name: "userManagement",
  initialState: DEFAULT_USER_STATE,
  reducers: {
    setCurrentUser: (state, action) => {
      state.currentUser = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      // Admin Get All Users
      .addCase(adminGetAllUsers.pending, (state) => {
        state.loading = true;
      })
      .addCase(adminGetAllUsers.fulfilled, (state, action) => {
        state.loading = false;
        state.userList = action.payload.result;
        state.totalCount = action.payload.totalDocument;
      })
      .addCase(adminGetAllUsers.rejected, (state) => {
        state.loading = false;
      });
  },
});

export const { setCurrentUser } = userSlice.actions;

export default userSlice.reducer;
