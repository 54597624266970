import React, { useState } from "react";
import { useSelector } from "react-redux";

const AdminEarning = ({ userHeader, userProducts, loading }) => {
  // redux logic
  const users = useSelector((state) => state.userManagement);

  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;
  const totalItems = userProducts.length;
  const totalPages = Math.ceil(totalItems / itemsPerPage);

  const handlePageChange = (page) => {
    if (page >= 1 && page <= totalPages) {
      setCurrentPage(page);
    }
  };

  // Calculate current items based on pagination
  const currentItems = userProducts.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  const generatePageNumbers = () => {
    const pages = new Set();

    // Always include the first page
    pages.add(1);

    // Include "..." before middle pages if needed
    if (currentPage > 3) pages.add("...");

    // Include pages around the current page
    const start = Math.max(2, currentPage - 1);
    const end = Math.min(totalPages - 1, currentPage + 1);
    for (let i = start; i <= end; i++) {
      pages.add(i);
    }

    // Include "..." after middle pages if needed
    if (currentPage < totalPages - 2) pages.add("...");

    // Always include the last page
    if (totalPages > 1) {
      pages.add(totalPages);
    }

    return Array.from(pages);
  };

  const paginationNumbers = generatePageNumbers();

  return (
    <div className="relative bg-card overflow-x-auto shadow-md sm:rounded-lg m-2">
      {loading ? (
        <div className="text-center text-gray-500">Loading...</div>
      ) : totalItems === 0 ? (
        <div className="text-center text-gray-500">No products found</div>
      ) : (
        <>
          <div className="overflow-x-auto">
            <table className="w-full text-sm text-left rtl:text-right bg-card  text-gray-500 dark:text-gray-400">
              <thead className="text-xs text-text uppercase bg-cardG ">
                <tr>
                  <th scope="col" className="p-4">
                    <div className="flex items-center">
                      <input
                        id="checkbox-all-search"
                        type="checkbox"
                        className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 dark:focus:ring-offset-gray-800 focus:ring-1 dark:bg-gray-700 dark:border-gray-600"
                        aria-label="Select all products"
                      />
                    </div>
                  </th>
                  {userHeader.map((header, index) => (
                    <th key={index} scope="col" className="px-6 py-3">
                      {header.label || header.key}
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {currentItems.map((product, index) => (
                  <tr
                    key={index}
                    className="bg-card border-b  dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600"
                  >
                    <td className="w-4 p-4">
                      <div className="flex items-center">
                        <input
                          type="checkbox"
                          className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 dark:focus:ring-offset-gray-800 focus:ring-1 dark:bg-gray-700 dark:border-gray-600"
                          aria-label={`Select product ${product.name}`}
                        />
                      </div>
                    </td>
                    <th
                      scope="row"
                      className="px-6 py-4 font-medium  whitespace-nowrap "
                    >
                      {product.name}
                    </th>
                    <td className="px-6 py-4">{product.email}</td>
                    <td className="px-6 py-4">{product.phone}</td>
                    <td className="px-6 py-4">{product.roleId}</td>
                    <td className="px-6 py-4">{product.status}</td>
                    <td className="px-6 py-4 gap-4 flex">
                      <a
                        href="#"
                        className="font-medium text-blue-600  border  border-blue-600  px-2 rounded-lg hover:text-white hover:bg-blue-600"
                      >
                        Edit
                      </a>
                      <a
                        href="#"
                        className="font-medium text-green-600  border  border-green-600  px-2 rounded-lg hover:text-white hover:bg-green-600"
                      >
                        Data
                      </a>
                      <a
                        href="#"
                        className="font-medium text-red-600  border  border-red-600  px-2 rounded-lg hover:text-white hover:bg-red-600"
                      >
                        Delete
                      </a>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
            <nav
              className="flex items-center justify-between m-4"
              aria-label="Table navigation"
            >
              <span className="text-sm font-normal text-black">
                Showing{" "}
                <span className="font-semibold text-text">
                  {(currentPage - 1) * itemsPerPage + 1} -{" "}
                  {Math.min(currentPage * itemsPerPage, totalItems)}
                </span>{" "}
                of <span className="font-semibold text-text">{totalItems}</span>
              </span>
              <ul className="inline-flex -space-x-px text-sm h-8">
                {/* Previous Button */}
                <li>
                  <button
                    onClick={() => handlePageChange(currentPage - 1)}
                    disabled={currentPage === 1}
                    className="flex items-center justify-center px-3 h-8 text-gray-500 bg-white border border-gray-300 rounded-l-lg hover:bg-gray-100"
                  >
                    Previous
                  </button>
                </li>

                {/* Page Numbers */}
                {paginationNumbers.map((page, index) => (
                  <li key={index}>
                    {page === "..." ? (
                      <span className="flex items-center justify-center px-3 h-8 text-gray-500 bg-white border border-gray-300">
                        ...
                      </span>
                    ) : (
                      <button
                        onClick={() => handlePageChange(page)}
                        className={`flex items-center justify-center px-3 h-8 ${
                          currentPage === page
                            ? "text-white bg-blue-500"
                            : "text-gray-500 bg-white"
                        } border border-gray-300 hover:bg-gray-100`}
                      >
                        {page}
                      </button>
                    )}
                  </li>
                ))}

                {/* Next Button */}
                <li>
                  <button
                    onClick={() => handlePageChange(currentPage + 1)}
                    disabled={currentPage === totalPages}
                    className="flex items-center justify-center px-3 h-8 text-gray-500 bg-white border border-gray-300 rounded-r-lg hover:bg-gray-100"
                  >
                    Next
                  </button>
                </li>
              </ul>
            </nav>
          </div>
        </>
      )}
    </div>
  );
};

export default AdminEarning;
