import React, { useState } from "react";
import { MdFilterList } from "react-icons/md";
import { IoClose } from "react-icons/io5";

const FilterButton = () => {
  const [isModalFilter, setIsModalFilter] = useState(false);

  const toggleModalFilter = () => {
    setIsModalFilter(!isModalFilter);
  };

  const handleFilter = (e) => {
    e.preventDefault(); // Prevent default form submission (if inside a form)
  };

  return (
    <div className="w-full lg:w-fit">
      <button
        onClick={toggleModalFilter}
        // className="flex items-center gap-2 justify-center w-full sm:w-auto bg-teal-500 hover:bg-teal-600 text-white font-medium rounded-lg text-sm px-4 py-2 transition-transform transform hover:scale-105"
        className="flex items-center gap-2 justify-center w-full sm:w-auto  hover:bg-teal-600 text-teal-500 hover:text-white border-[1px] border-teal-500 font-medium rounded-lg text-sm px-4 py-1 transition-transform transform hover:scale-105"
      >
        <MdFilterList /> Filter
      </button>

      {/* Filter Modal */}
      {isModalFilter && (
        <div
          id="crud-modal"
          className="fixed inset-0 z-50 flex items-center justify-center overflow-y-auto overflow-x-hidden bg-gray-900 bg-opacity-50"
        >
          <div className="relative p-4 w-full max-w-xs sm:max-w-sm md:max-w-md lg:max-w-lg xl:max-w-xl mx-auto">
            {/* Modal Content */}
            <div className="relative bg-card rounded-lg shadow ">
              {/* Modal Header */}
              <div className="flex items-center justify-between p-4 md:p-5 border-b rounded-t ">
                <h3 className="text-lg font-semibold text-text">Filter User</h3>
                <button
                  type="button"
                  className="text-text bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 inline-flex justify-center items-center dark:hover:bg-gray-600 "
                  onClick={toggleModalFilter}
                >
                  <IoClose className="w-5 h-5" />
                </button>
              </div>

              {/* Modal Body */}
              <form onClick={handleFilter} className="p-4 md:p-5">
                <div className="grid gap-4 mb-4 grid-cols-1 sm:grid-cols-2">
                  <div>
                    <label
                      htmlFor="dateTo"
                      className="block mb-2 text-sm font-medium text-text"
                    >
                      Date to
                    </label>
                    <input
                      type="date"
                      id="dateTo"
                      className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                      required
                    />
                  </div>
                  <div>
                    <label
                      htmlFor="dateFrom"
                      className="block mb-2 text-sm font-medium text-text"
                    >
                      Date From
                    </label>
                    <input
                      type="date"
                      id="dateFrom"
                      className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                      required
                    />
                  </div>

                  <div className="flex items-center">
                    <input
                      id="active"
                      type="checkbox"
                      className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                    />
                    <label
                      htmlFor="active"
                      className="ml-2 text-sm font-medium text-text"
                    >
                      Active
                    </label>
                  </div>

                  <div className="flex items-center">
                    <input
                      id="inactive"
                      type="checkbox"
                      className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                    />
                    <label
                      htmlFor="inactive"
                      className="ml-2 text-sm font-medium text-text"
                    >
                      Inactive
                    </label>
                  </div>
                </div>
                <button
                  type="submit"
                  className="w-full mt-2 justify-center text-white inline-flex items-center bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-4 py-2 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                >
                  Submit
                </button>
              </form>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default FilterButton;
