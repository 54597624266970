// actions/users.js
import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { getAuthHeader } from "../../utills/tools";
import { errorGlobal } from "../reducers/notification";

export const adminGetAllUsers = createAsyncThunk(
    "admin/getAllUsers",
    async (data, { dispatch, rejectWithValue }) => {
      try {
        const response = await axios.post(
          `${process.env.REACT_APP_API_URL}/user/admin/users`,
          data,
          getAuthHeader()
        );
        return response.data;
      } catch (error) {
        // Dispatch global error action
        dispatch(errorGlobal(error.response?.data?.message || "An error occurred"));
        return rejectWithValue(error.response?.data || "An error occurred");
      }
    }
);
