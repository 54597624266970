import React from "react";
import Recharge from "../../assets/dashboard/rechargeLogo.png";
import JioLogo from "../../assets/dashboard/Reliance_Jio.png";
import Reliance from "../../assets/dashboard/Reliance_Jio.png";
import Airtel from "../../assets/dashboard/airtel.png";
import Vi from "../../assets/dashboard/Vi-Logo.png";
import Offer from "../../assets/dashboard/offer.png";
import OfferBot from "../../assets/dashboard/offerBot.png";
import DashboardSlider from "./userdata/DashboardSlider";
import UserTable from "../../components/table/AdminUsersTable";

const UserDashboard = () => {
  const services = [
    { id: 1, name: "Mobile Recharge", img: Recharge },
    { id: 2, name: "Utility Bills", img: Recharge },
    { id: 3, name: "Internet Package", img: Recharge },
    { id: 4, name: "Data Packages", img: Recharge },
    { id: 5, name: "Electricity Bill", img: Recharge },
    { id: 6, name: "Gas Bill", img: Recharge },
    { id: 7, name: "Insurance Payment", img: Recharge },
    { id: 8, name: "Cable TV", img: Recharge },
    { id: 9, name: "Loan Repayment", img: Recharge },
    { id: 10, name: "Tuition Fee", img: Recharge },
    { id: 11, name: "Credit Card Bill", img: Recharge },
    { id: 12, name: "Subscriptions", img: Recharge },
    { id: 13, name: "Mobile Plan", img: Recharge },
  ];
  const userProducts = [
    {
      name: 'Apple MacBook Pro 17"',
      color: "Silver",
      category: "Laptop",
      price: "$2999",
    },
    {
      name: 'Apple MacBook Pro 17"',
      color: "Silver",
      category: "Laptop",
      price: "$2999",
    },
    {
      name: "Microsoft Surface Pro",
      color: "White",
      category: "Laptop PC",
      price: "$1999",
    },
    {
      name: "Magic Mouse 2",
      color: "Black",
      category: "Accessories",
      price: "$99",
    },
    { name: "Apple Watch", color: "Black", category: "Watches", price: "$199" },
    { name: "Apple iMac", color: "Silver", category: "PC", price: "$2999" },
    {
      name: "Apple AirPods",
      color: "White",
      category: "Accessories",
      price: "$399",
    },
    { name: "iPad Pro", color: "Gold", category: "Tablet", price: "$699" },
    {
      name: "Magic Keyboard",
      color: "Black",
      category: "Accessories",
      price: "$99",
    },
    {
      name: "Smart Folio iPad Air",
      color: "Blue",
      category: "Accessories",
      price: "$79",
    },
    { name: "AirTag", color: "Silver", category: "Accessories", price: "$29" },
    {
      name: 'Apple MacBook Pro 17"',
      color: "Silver",
      category: "Laptop",
      price: "$2999",
    },
    {
      name: 'Apple MacBook Pro 17"',
      color: "Silver",
      category: "Laptop",
      price: "$2999",
    },
    {
      name: "Microsoft Surface Pro",
      color: "White",
      category: "Laptop PC",
      price: "$1999",
    },
    {
      name: "Magic Mouse 2",
      color: "Black",
      category: "Accessories",
      price: "$99",
    },
    { name: "Apple Watch", color: "Black", category: "Watches", price: "$199" },
    { name: "Apple iMac", color: "Silver", category: "PC", price: "$2999" },
    {
      name: "Apple AirPods",
      color: "White",
      category: "Accessories",
      price: "$399",
    },
    { name: "iPad Pro", color: "Gold", category: "Tablet", price: "$699" },
    {
      name: "Magic Keyboard",
      color: "Black",
      category: "Accessories",
      price: "$99",
    },
    {
      name: "Smart Folio iPad Air",
      color: "Blue",
      category: "Accessories",
      price: "$79",
    },
    { name: "AirTag", color: "Silver", category: "Accessories", price: "$29" },
    {
      name: 'Apple MacBook Pro 17"',
      color: "Silver",
      category: "Laptop",
      price: "$2999",
    },
    {
      name: 'Apple MacBook Pro 17"',
      color: "Silver",
      category: "Laptop",
      price: "$2999",
    },
    {
      name: "Microsoft Surface Pro",
      color: "White",
      category: "Laptop PC",
      price: "$1999",
    },
    {
      name: "Magic Mouse 2",
      color: "Black",
      category: "Accessories",
      price: "$99",
    },
    { name: "Apple Watch", color: "Black", category: "Watches", price: "$199" },
    { name: "Apple iMac", color: "Silver", category: "PC", price: "$2999" },
    {
      name: "Apple AirPods",
      color: "White",
      category: "Accessories",
      price: "$399",
    },
    { name: "iPad Pro", color: "Gold", category: "Tablet", price: "$699" },
    {
      name: "Magic Keyboard",
      color: "Black",
      category: "Accessories",
      price: "$99",
    },
    {
      name: "Smart Folio iPad Air",
      color: "Blue",
      category: "Accessories",
      price: "$79",
    },
    { name: "AirTag", color: "Silver", category: "Accessories", price: "$29" },
    {
      name: 'Apple MacBook Pro 17"',
      color: "Silver",
      category: "Laptop",
      price: "$2999",
    },
    {
      name: 'Apple MacBook Pro 17"',
      color: "Silver",
      category: "Laptop",
      price: "$2999",
    },
    {
      name: "Microsoft Surface Pro",
      color: "White",
      category: "Laptop PC",
      price: "$1999",
    },
    {
      name: "Magic Mouse 2",
      color: "Black",
      category: "Accessories",
      price: "$99",
    },
    { name: "Apple Watch", color: "Black", category: "Watches", price: "$199" },
    { name: "Apple iMac", color: "Silver", category: "PC", price: "$2999" },
    {
      name: "Apple AirPods",
      color: "White",
      category: "Accessories",
      price: "$399",
    },
    { name: "iPad Pro", color: "Gold", category: "Tablet", price: "$699" },
    {
      name: "Magic Keyboard",
      color: "Black",
      category: "Accessories",
      price: "$99",
    },
    {
      name: "Smart Folio iPad Air",
      color: "Blue",
      category: "Accessories",
      price: "$79",
    },
    { name: "AirTag", color: "Silver", category: "Accessories", price: "$29" },
    {
      name: 'Apple MacBook Pro 17"',
      color: "Silver",
      category: "Laptop",
      price: "$2999",
    },
    {
      name: 'Apple MacBook Pro 17"',
      color: "Silver",
      category: "Laptop",
      price: "$2999",
    },
    {
      name: "Microsoft Surface Pro",
      color: "White",
      category: "Laptop PC",
      price: "$1999",
    },
    {
      name: "Magic Mouse 2",
      color: "Black",
      category: "Accessories",
      price: "$99",
    },
    { name: "Apple Watch", color: "Black", category: "Watches", price: "$199" },
    { name: "Apple iMac", color: "Silver", category: "PC", price: "$2999" },
    {
      name: "Apple AirPods",
      color: "White",
      category: "Accessories",
      price: "$399",
    },
    { name: "iPad Pro", color: "Gold", category: "Tablet", price: "$699" },
    {
      name: "Magic Keyboard",
      color: "Black",
      category: "Accessories",
      price: "$99",
    },
    {
      name: "Smart Folio iPad Air",
      color: "Blue",
      category: "Accessories",
      price: "$79",
    },
    { name: "AirTag", color: "Silver", category: "Accessories", price: "$29" },
    { name: "iPad Pro", color: "Gold", category: "Tablet", price: "$699" },
    {
      name: "Magic Keyboard",
      color: "Black",
      category: "Accessories",
      price: "$99",
    },
    {
      name: "Smart Folio iPad Air",
      color: "Blue",
      category: "Accessories",
      price: "$79",
    },
    { name: "AirTag", color: "Silver", category: "Accessories", price: "$29" },
    {
      name: 'Apple MacBook Pro 17"',
      color: "Silver",
      category: "Laptop",
      price: "$2999",
    },
    {
      name: 'Apple MacBook Pro 17"',
      color: "Silver",
      category: "Laptop",
      price: "$2999",
    },
    {
      name: "Microsoft Surface Pro",
      color: "White",
      category: "Laptop PC",
      price: "$1999",
    },
    {
      name: "Magic Mouse 2",
      color: "Black",
      category: "Accessories",
      price: "$99",
    },
    { name: "Apple Watch", color: "Black", category: "Watches", price: "$199" },
    { name: "Apple iMac", color: "Silver", category: "PC", price: "$2999" },
    {
      name: "Apple AirPods",
      color: "White",
      category: "Accessories",
      price: "$399",
    },
    { name: "iPad Pro", color: "Gold", category: "Tablet", price: "$699" },
    {
      name: "Magic Keyboard",
      color: "Black",
      category: "Accessories",
      price: "$99",
    },
    {
      name: "Smart Folio iPad Air",
      color: "Blue",
      category: "Accessories",
      price: "$79",
    },
    { name: "AirTag", color: "Silver", category: "Accessories", price: "$29" },
  ];
  const userHeader = [
    { key: "Name" },
    { key: "Email" },
    { key: "Phone" },
    { key: "Role Id" },
    { key: "Status" },
    { key: "Action" },
  ];

  return (
    <>
      <div className=" ">
        <DashboardSlider />

        <div className="flex flex-col md:flex-col lg:flex-row gap-1 mt-0 px-2">
          <div className="md:w-full lg:w-[40%]">
            <div class="w-full p-4 bg-card border border-gray-200 rounded-lg shadow sm:p-6 md:p-4 dark:bg-gray-800 dark:border-gray-700">
              <form class="space-y-2" action="#">
                <h4 class="text-sm font-medium text-text">
                  Recharge Your Prepaid Mobile
                </h4>
                <div>
                  <input
                    type="text"
                    name="number"
                    id="number"
                    class="bg-card border border-gray-300 text-text text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2 dark:border-gray-500 dark:placeholder-gray-400"
                    placeholder="Enter Your Mobile Number"
                    pattern="[0-9]{10}"
                    title="Please enter a valid 10-digit phone number"
                    maxlength="10"
                    required
                  />
                </div>
                <div className="">
                  <select
                    name="network"
                    id="network"
                    class="bg-card text-text border border-gray-300 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2 dark:border-gray-500 dark:placeholder-gray-400 option-style"
                    required
                  >
                    <option value="">Select Your Network</option>
                    <option value="airtel">Airtel</option>
                    <option value="vodafone">Vodafone</option>
                    <option value="jio">Jio</option>
                    <option value="bsnl">BSNL</option>
                  </select>
                </div>

                <div>
                  <select
                    name="circle"
                    id="circle"
                    class="bg-card border border-gray-300 text-text text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white"
                    required
                  >
                    <option value="">Select Your Circle</option>
                    <option value="delhi">Delhi</option>
                    <option value="mumbai">Mumbai</option>
                    <option value="kolkata">Kolkata</option>
                    <option value="chennai">Chennai</option>
                    <option value="bangalore">Bangalore</option>
                  </select>
                </div>
                <div>
                  <input
                    type="number"
                    name="amount"
                    id="amount"
                    class=" border border-gray-300 text-text bg-card text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2 dark:border-gray-500 dark:placeholder-gray-400 "
                    placeholder="Enter Recharge Amount"
                    required
                  />
                </div>
                <button
                  type="submit"
                  class="w-full text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                >
                  Pay Now
                </button>
              </form>
              <div className="flex flex-col gap-1">
                <h4 class="text-sm font-medium text-text mb-2 mt-3">
                  Previous Transaction
                </h4>
                <div className="flex flex-row justify-between items-center gap-4 border border-gray-300 p-1 rounded-md">
                  <div className="flex flex-row gap-2 items-center">
                    <div className="flex-shrink-0">
                      <img src={JioLogo} alt="user" className="w-7 h-7" />
                    </div>
                    <div className="flex flex-col justify-start">
                      <h5 className="text-xs sm:text-sm text-text">
                        7880829986
                      </h5>
                      <h5 className="text-xs sm:text-sm text-gray-500">
                        05/05/2022
                      </h5>
                    </div>
                  </div>
                  <div className="flex-shrink-0">
                    <button className="text-sm sm:text-base font-medium text-text bg-background border border-gray-300 px-2 py-0 rounded-md ">
                      ₹ 159
                    </button>
                  </div>
                </div>
                <div className="flex flex-row justify-between items-center gap-4 border border-gray-300 p-1 rounded-md">
                  <div className="flex flex-row gap-2 items-center">
                    <div className="flex-shrink-0">
                      <img src={Reliance} alt="user" className="w-7 h-7" />
                    </div>
                    <div className="flex flex-col justify-start">
                      <h5 className="text-xs sm:text-sm text-text">
                        7880829986
                      </h5>
                      <h5 className="text-xs sm:text-sm text-gray-500 ">
                        05/05/2022
                      </h5>
                    </div>
                  </div>
                  <div className="flex-shrink-0">
                    <button className="text-sm sm:text-base font-medium text-text bg-background border border-gray-300 px-2 py-0 rounded-md">
                      ₹ 229
                    </button>
                  </div>
                </div>
                <div className="flex flex-row justify-between items-center gap-4 border border-gray-300 p-1 rounded-md">
                  <div className="flex flex-row gap-2 items-center">
                    <div className="flex-shrink-0">
                      <img src={Airtel} alt="user" className="w-7 h-7" />
                    </div>
                    <div className="flex flex-col justify-start">
                      <h5 className="text-xs sm:text-sm text-text">
                        7880829986
                      </h5>
                      <h5 className="text-xs sm:text-sm text-gray-500 ">
                        05/05/2022
                      </h5>
                    </div>
                  </div>
                  <div className="flex-shrink-0">
                    <button className="text-sm sm:text-base font-medium text-text bg-background border border-gray-300 px-2 py-0 rounded-md">
                      ₹ 799
                    </button>
                  </div>
                </div>
                <div className="flex flex-row justify-between items-center gap-4 border border-gray-300 p-1 rounded-md">
                  <div className="flex flex-row gap-2 items-center">
                    <div className="flex-shrink-0">
                      <img src={Vi} alt="user" className="w-7 h-7" />
                    </div>
                    <div className="flex flex-col justify-start">
                      <h5 className="text-xs sm:text-sm text-text">
                        7880829986
                      </h5>
                      <h5 className="text-xs sm:text-sm text-gray-500 ">
                        05/05/2022
                      </h5>
                    </div>
                  </div>
                  <div className="flex-shrink-0">
                    <button className="text-sm sm:text-base font-medium text-text bg-background border border-gray-300 px-2 py-0 rounded-md hover:bg-gray-200">
                      ₹ 399
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="w-full lg:w-[60%] p-4  bg-card border border-gray-200 rounded-lg shadow sm:p-6 md:p-4  dark:border-gray-700">
            <div className="flex flex-row gap-4 items-center">
              <img src={Offer} alt="user" className="w-12 h-12 m-aut0" />
              <h2 className="text-lg md:text-xl font-medium text-text">
                Offers
              </h2>
            </div>
            <div className="mt-6 mx-6 ">
              <ol className="list-decimal space-y-2 ml-2">
                <li className="text-xs md:text-sm font-basic text-text">
                  Axis Neo Card offer 5% up to Rs.150 off on recharge/bill
                  payment of min. Rs.299 using Axis Neo credit card before 30
                  April. Details
                </li>
                <li className="text-xs md:text-sm font-basic text-text">
                  Axis Neo Card offer 5% up to Rs.150 off on recharge/bill
                  payment of min. Rs.299 using Axis Neo credit card before 30
                  April. Details
                </li>
                <li className="text-xs md:text-sm font-basic text-text">
                  Axis Neo Card offer 5% up to Rs.150 off on recharge/bill
                  payment of min. Rs.299 using Axis Neo credit card before 30
                  April. Details
                </li>
              </ol>
            </div>
            <div className="flex justify-center mt-4">
              <img
                src={OfferBot}
                alt="offer bot"
                className="w-full max-w-[200px] sm:max-w-[250px] md:max-w-[300px] lg:max-w-[300px] h-auto rounded-lg"
              />
            </div>
          </div>
        </div>
        <div>
          <UserTable userHeader={userHeader} userProducts={userProducts} />
        </div>
      </div>
    </>
  );
};

export default UserDashboard;
