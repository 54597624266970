import React, { useState, useRef, useEffect } from "react";
import { MdOutlineNotificationsActive } from "react-icons/md";
import { IoWalletOutline } from "react-icons/io5";
import { FaMoon } from "react-icons/fa";
import { IoSunny } from "react-icons/io5";
import { Link, useNavigate } from "react-router-dom";
import { IoReorderThreeOutline } from "react-icons/io5";
import { LuUserCircle2 } from "react-icons/lu";
import { PiFileTextBold } from "react-icons/pi";
import { IoClose, IoEyeOff, IoEye } from "react-icons/io5";
import { useDispatch } from "react-redux";
import { logout } from "../../../store/actions/auth";
import { showToast } from "../../../utills/tools";

const AdminHeader = ({ title, user, profileItem, onMobileSide }) => {
  const dispatch = useDispatch(); // Correctly using useDispatch at the top level
  const navigate = useNavigate(); // Correctly using useNavigate at the top level

  const handleLogout = async () => {
    await dispatch(logout()); // Dispatch the logout action
    showToast("SUCCESS", "Logout successful!");
    navigate("/"); // Redirect after logout
  };

  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const dropdownRef = useRef(null);
  const [isDarkMode, setIsDarkMode] = useState(false);
  const [isModalEdit, setIsModalEdit] = useState(false);

  // Toggle the dropdown
  const toggleDropdown = () => {
    setIsDropdownOpen((prev) => !prev);
  };
  const toggleSidebar = () => {
    onMobileSide((prev) => !prev);
  };

  // Close dropdown if clicked outside
  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsDropdownOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  // Retrieve saved theme from localStorage on component mount
  useEffect(() => {
    const storedTheme = localStorage.getItem("theme");
    if (storedTheme === "dark") {
      document.documentElement.setAttribute("data-theme", "dark");
      setIsDarkMode(true);
    } else {
      document.documentElement.setAttribute("data-theme", "light");
      setIsDarkMode(false);
    }
  }, []);

  // Toggle theme and update localStorage
  const toggleTheme = () => {
    const theme = isDarkMode ? "light" : "dark";
    document.documentElement.setAttribute("data-theme", theme);
    localStorage.setItem("theme", theme);
    setIsDarkMode(!isDarkMode);
  };
  const toggleModalEdit = () => {
    setIsModalEdit(true);
  };
  const toggleModalEditClose = () => {
    setIsModalEdit(false);
  };

  return (
    <nav className="bg-new dark:bg-background border-b border-gray-200 w-full fixed top-0 left-0 z-50">
      <div className="max-w-screen-xl flex flex-wrap items-center justify-between mx-auto p-1">
        <div className="flex flex-row items-center justify-center gap-2 ">
          {/* Hamburger button for mobile menu */}
          <button
            data-collapse-toggle="navbar-user"
            type="button"
            className="lg:hidden inline-flex items-center mt-1 p-1 w-8 h-8 justify-center text-sm text-gray-500 rounded-lg  hover:bg-gray-100 focus:outline-none dark:text-gray-400 dark:hover:bg-gray-700"
            aria-controls="navbar-user"
            aria-expanded={isMobileMenuOpen}
            onClick={toggleSidebar}
          >
            <span className="sr-only">Open main menu</span>
            <IoReorderThreeOutline className="text-2xl" />
          </button>
          <h2 className="self-center text-xl font-medium whitespace-nowrap dark:text-white">
            {title}
          </h2>
        </div>

        <div className="flex flex-row gap-2 md:gap-6 lg:gap-8 items-center justify-center">
          <div>
            <button
              onClick={toggleTheme}
              className="p-2 text-sm transition duration-300  dark:bg-gray-500 rounded-md text-gray-800 dark:text-gray-100"
            >
              {isDarkMode ? <IoSunny className="text-lg text-white"/> : <FaMoon className="text-lg"/>}
            </button>
          </div>
          <div className="flex justify-center">
            <button onClick={toggleModalEdit}>
              <PiFileTextBold className="text-2xl text-text cursor-pointer" />
            </button>
          </div>
          {isModalEdit && (
            <div
              id="crud-modal"
              className="fixed inset-0 z-50 flex items-center justify-center overflow-y-auto overflow-x-hidden bg-gray-900 bg-opacity-10"
            >
              <div className="relative p-4 w-full max-w-md h-auto">
                {/* Modal Content */}
                <div className="relative bg-card rounded-lg shadow ">
                  {/* Modal Header */}
                  <div className="flex items-center justify-between p-4 md:p-5 border-b rounded-t dark:border-gray-600">
                    <h3 className="text-lg font-semibold text-text">
                      Create Complaint
                    </h3>
                    <button
                      type="button"
                      className="text-text bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white"
                      onClick={toggleModalEditClose}
                    >
                      <IoClose className="w-5 h-5" />
                    </button>
                  </div>

                  {/* Modal Body */}
                  <form className="p-4 md:p-5">
                    <div className="grid gap-4 mb-4">
                      <div className="">
                        <label
                          htmlFor="subject"
                          className="block mb-2 text-sm font-medium text-gtext"
                        >
                          Subject
                        </label>
                        <input
                          type="text"
                          id="subject"
                          name="subject"
                          className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                          placeholder="Subject"
                          required
                        />
                      </div>

                      <div>
                        <label
                          htmlFor="fileUpload"
                          className="block mb-2 text-sm font-medium text-text"
                        >
                          Upload File
                        </label>
                        <input
                          type="file"
                          id="fileUpload"
                          name="fileUpload"
                          className="block w-full text-sm text-gray-900 border border-gray-300 rounded-lg cursor-pointer bg-gray-50 dark:text-gray-400 focus:outline-none dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400"
                        />
                      </div>

                      <div>
                        <label
                          htmlFor="complaint"
                          className="block mb-2 text-sm font-medium text-text"
                        >
                          Complaint
                        </label>
                        <textarea
                          id="complaint"
                          name="complaint"
                          rows="2"
                          className="block w-full text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white"
                          placeholder="Enter your complaint here..."
                        ></textarea>
                      </div>
                    </div>

                    <button
                      type="submit"
                      className="text-white w-full justify-center inline-flex items-center bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                    >
                      Save
                    </button>
                  </form>
                </div>
              </div>
            </div>
          )}

          <div>
            <MdOutlineNotificationsActive className="text-2xl text-text cursor-pointer" />
          </div>

          <div className="flex flex-row items-center justify-center">
            <div className="bg-background dark:bg-card p-1 rounded-l dark:text-customBg">
              <IoWalletOutline className="text-xl " />
            </div>
            <div className="bg-background rounded-r">
              <h4 className="text-base font-normal px-2">{user?.amount}</h4>
            </div>
          </div>

          <div className="flex items-center md:order-2 space-x-3 md:space-x-0 ">
            <button
              type="button"
              className="flex text-2xl text-text rounded-full md:me-0  "
              id="user-menu-button"
              aria-expanded={isDropdownOpen}
              onClick={toggleDropdown}
            >
              <LuUserCircle2 />
            </button>
            {/* Dropdown menu */}
            {isDropdownOpen && (
              <div
                ref={dropdownRef}
                className="z-50 my-4 text-base list-none bg-background divide-y divide-gray-100 rounded-lg shadow  dark:divide-gray-600 absolute right-3 top-5" // Position dropdown
                id="user-dropdown"
              >
                <div className="px-4 py-3">
                  <span className="block text-sm text-text ">
                    {user.userName}
                  </span>
                  <span className="block text-sm text-text truncate ">
                    {user.email}
                  </span>
                </div>
                <ul className="py-2" aria-labelledby="user-menu-button">
                  <li>
                    <Link
                      to="#"
                      className="block px-4 py-2 text-sm text-text hover:bg-gray-300 "
                    >
                      {profileItem.dashboard}
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="#"
                      className="block px-4 py-2 text-sm text-text hover:bg-gray-300 "
                    >
                      {profileItem.settings}
                    </Link>
                  </li>

                  <li>
                    <Link
                      to="#"
                      className="block px-4 py-2 text-sm text-text hover:bg-gray-300"
                      onClick={handleLogout}
                    >
                      Sign out
                    </Link>
                  </li>
                </ul>
              </div>
            )}
          </div>
        </div>
      </div>
    </nav>
  );
};

export default AdminHeader;
