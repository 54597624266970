import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import { IoMdLogOut } from "react-icons/io";

const AdminSidebar = ({ sidebarItem, sidebarOpen, closeSidebar }) => {
  const location = useLocation(); // Get the current route path

  const [openDropdown, setOpenDropdown] = useState(null);

  // Disable scrolling when the sidebar is open on mobile
  useEffect(() => {
    if (sidebarOpen) {
      document.body.classList.add("overflow-hidden");
    } else {
      document.body.classList.remove("overflow-hidden");
    }
  }, [sidebarOpen]);

  const handleDropdown = (id) => {
    setOpenDropdown(openDropdown === id ? null : id);
  };
  return (
    <>
      <div className="relative">
        <aside
          id="sidebar-multi-level-sidebar"
          className={`fixed top-11 left-0 z-40 w-64 h-full bg-card  transition-transform ${
            sidebarOpen ? "translate-x-0" : "-translate-x-full"
          } sm:translate-x-0`}
          aria-label="Sidebar"
        >
          <div className="h-[90vh] flex flex-col justify-between">
            <div className=" px-3 overflow-y-auto">
              <ul className="space-y-1 font-medium">
                {sidebarItem.map((item) => (
                  <li key={item.id} className="flex flex-col mt-1">
                    <Link
                      to={`/admin/${item.link}`}
                      onClick={() => {
                        if (item.dropdown) {
                          handleDropdown(item.id);
                        } else {
                          closeSidebar();
                        }
                      }}
                      className={`flex items-center gap-3 px-3 py-2 rounded-lg transition-colors duration-200 ${
                        location.pathname === `/admin/${item.link}` ||
                        location.pathname === item.link
                          ? "bg-background text-text" // Active styles
                          : "text-text hover:bg-background"
                      }`}
                    >
                      <span className="text-base text-text">{item.logo}</span>
                      <span className="text-sm font-medium">{item.name}</span>
                      {/* Arrow indicator for dropdown */}
                      {item.dropdown && (
                        <span className="ml-auto text-sm">
                          {openDropdown === item.id ? "▲" : "▼"}
                        </span>
                      )}
                    </Link>

                    {/* Render dropdown if item has dropdown array and is open */}
                    {item.dropdown && openDropdown === item.id && (
                      <ul className="pl-8 space-y-1 mt-2">
                        {item.dropdown.map((subItem) => (
                          <li key={subItem.id}>
                            <Link
                              to={`/admin/${subItem.link}`}
                              className={`flex items-center gap-3 px-3 py-2 rounded-lg transition-colors duration-200 ${
                                location.pathname ===
                                  `/admin/${subItem.link}` ||
                                location.pathname === subItem.link
                                  ? "bg-background text-text" // Active styles
                                  : "text-text hover:bg-background"
                              }`}
                              onClick={closeSidebar}
                            >
                              <span className="text-base text-text">
                                {subItem.logo}
                              </span>
                              <span className="text-sm font-medium">
                                {subItem.name}
                              </span>
                            </Link>
                          </li>
                        ))}
                      </ul>
                    )}
                  </li>
                ))}
              </ul>
            </div>
            <div className="px-3">
              <button
                // onClick={handelLogout}
                className="w-full flex items-center justify-center gap-2 px-4 py-2 bg-red-500 text-white text-sm font-medium rounded-lg hover:bg-red-600 transition-colors duration-200"
              >
                <IoMdLogOut className="text-lg" /> Logout
              </button>
            </div>
          </div>
        </aside>
      </div>
    </>
  );
};

export default AdminSidebar;
