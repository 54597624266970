import React from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import Recharge from "../../../assets/dashboard/rechargeLogo.png";
import "./DashboardSlider.css";

const ResponsiveSlider = () => {
  const services = [
    { id: 1, name: "Mobile Recharge", img: Recharge },
    { id: 2, name: "Utility Bills", img: Recharge },
    { id: 3, name: "Internet Package", img: Recharge },
    { id: 4, name: "Data Packages", img: Recharge },
    { id: 5, name: "Electricity Bill", img: Recharge },
    { id: 6, name: "Gas Bill", img: Recharge },
    { id: 7, name: "Insurance Payment", img: Recharge },
    { id: 8, name: "Cable TV", img: Recharge },
    { id: 9, name: "Loan Repayment", img: Recharge },
    { id: 10, name: "Tuition Fee", img: Recharge },
    { id: 11, name: "Credit Card Bill", img: Recharge },
    { id: 12, name: "Subscriptions", img: Recharge },
    { id: 13, name: "Mobile Plan", img: Recharge },
  ];

  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <div className="dashboard-slider">
      <Slider {...settings}>
        {services.map(({ id, name, img }) => (
          <div
            key={id}
            className="dashboard-slider-item flex flex-row items-center justify-center p-1 bg-card border border-gray-300 rounded-lg"
          >
            <img src={img} alt={name} className="dashboard-service-image" />
            <h3 className="text-text text-sm font-normal">{name}</h3>
          </div>
        ))}
      </Slider>
    </div>
  );
};

export default ResponsiveSlider;
