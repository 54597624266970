import React from "react";
// import { ToastContainer } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css"; // Import styles
import { Routes, Route } from "react-router-dom";
import Layout from "./components/layout/Layout";
import UserDashboard from "./pages/userPages/UserDashboard";
import UserSetting from "./pages/userPages/UserSetting";
import "./index.css";
import AdminLayout from "./components/adminLayout/AdminLayout";
import Login from "./pages/auth/Login";
import Register from "./pages/auth/Register";
import AdminDashboard from "./pages/adminPages/adminDashboard/AdminDashboard";
import AdminSetting from "./pages/adminPages/adminSetting/AdminSetting";
import Users from "./pages/adminPages/users/Users";
import Recharge from "./pages/adminPages/recharge/Recharge";
import Payments from "./pages/adminPages/payments/Payments";
import WalletReport from "./pages/adminPages/walletReport/WalletReport";
import OrderTxn from "./pages/adminPages/orderTxn/OrderTxn";
import Api from "./pages/adminPages/api/Api";
import Complaints from "./pages/adminPages/complaints/Complaints";
import Logs from "./pages/adminPages/logs/Logs";
import Earning from "./pages/adminPages/earning/Earning";
import Margin from "./pages/adminPages/margin/Margin";
import Charges from "./pages/adminPages/charges/Charges";
import Service from "./pages/adminPages/service/Service";
import Provider from "./pages/adminPages/provider/Provider";
import Data from "./pages/Data";
import Category from "./pages/adminPages/category/Category";

const App = () => {
  return (
    <>
      {/* ToastContainer outside of Routes for global access */}
      {/* <ToastContainer /> */}
      <Routes>
        {/* <Route path="data" element={<Data />} /> */}

        <Route path="/" element={<Login />} />
        <Route path="register" element={<Register />} />
        <Route path="/admin" element={<AdminLayout />}>
          <Route index element={<AdminDashboard />} />
          <Route path="adminSetting" element={<AdminSetting />} />
          <Route path="users" element={<Users />} />
          <Route path="recharge" element={<Recharge />} />
          <Route path="payments" element={<Payments />} />
          <Route path="walletReport" element={<WalletReport />} />
          <Route path="orderTxn" element={<OrderTxn />} />
          <Route path="api" element={<Api />} />
          <Route path="earning" element={<Earning />} />
          <Route path="complaints" element={<Complaints />} />
          <Route path="logs" element={<Logs />} />
          <Route path="margin" element={<Margin />} />
          <Route path="charges" element={<Charges />} />
          <Route path="service" element={<Service />} />
          <Route path="provider" element={<Provider />} />
          <Route path="category" element={<Category />} />
        </Route>
        <Route path="/user" element={<Layout />}>
          <Route index element={<UserDashboard />} />
          <Route path="userSetting" element={<UserSetting />} />
        </Route>
      </Routes>
    </>
  );
};

export default App;
