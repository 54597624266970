import { createAsyncThunk } from "@reduxjs/toolkit";
import { errorGlobal, successGlobal } from "../reducers/notification";
import { getAuthHeader, removeTokenCookie } from "../../utills/tools";
import axios from "axios";

// Register User Action
export const registerUser = createAsyncThunk(
  "auth/register",
  async (data, { dispatch, rejectWithValue }) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/auth/register`,
        data
      );
      dispatch(successGlobal("Registration successful"));
      return response.data;
    } catch (error) {
      const errorMessage = error.response?.data?.message || "Registration failed";
      dispatch(errorGlobal(errorMessage));
      return rejectWithValue(errorMessage);
    }
  }
);

// Login User Action
export const loginUser = createAsyncThunk(
  "auth/login",
  async (data, { dispatch, rejectWithValue }) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/auth/login`,
        data
      );

      if (!response.data.user._id) {
        const errorMessage = "Unable to login, please try again!";
        dispatch(errorGlobal(errorMessage));
        return rejectWithValue({ success: false, message: errorMessage });
      }

      dispatch(successGlobal("Login successful"));
      return response.data;
    } catch (error) {
      const errorMessage = error.response?.data?.message || "Login failed";
      dispatch(errorGlobal(errorMessage));
      return rejectWithValue(errorMessage);
    }
  }
);

// Logout User Action
export const logout = createAsyncThunk(
  "auth/logout",
  async (data, { dispatch }) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/auth/logout`,
        getAuthHeader()
      );
      dispatch(successGlobal(response.data.message));
      removeTokenCookie();
      return response.data;
    } catch (error) {
      dispatch(errorGlobal(error.response.data.message));
      return error.response.data;
    }
  }
);
