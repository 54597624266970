import React, { useState, useRef, useEffect } from "react";
import { MdOutlineNotificationsActive } from "react-icons/md";
import { IoWalletOutline } from "react-icons/io5";
import { FaMoon } from "react-icons/fa";
import { IoSunny } from "react-icons/io5";
import { Link } from "react-router-dom";
import { IoReorderThreeOutline } from "react-icons/io5";
import { LuUserCircle2 } from "react-icons/lu";

const Header = ({ title, user, profileItem, onMobileSide }) => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const dropdownRef = useRef(null);
  const [isDarkMode, setIsDarkMode] = useState(false);

  // Toggle the dropdown
  const toggleDropdown = () => {
    setIsDropdownOpen((prev) => !prev);
  };
  const toggleSidebar = () => {
    onMobileSide((prev) => !prev);
  };

  // Close dropdown if clicked outside
  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsDropdownOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  // Retrieve saved theme from localStorage on component mount
  useEffect(() => {
    const storedTheme = localStorage.getItem("theme");
    if (storedTheme === "dark") {
      document.documentElement.setAttribute("data-theme", "dark");
      setIsDarkMode(true);
    } else {
      document.documentElement.setAttribute("data-theme", "light");
      setIsDarkMode(false);
    }
  }, []);

  // Toggle theme and update localStorage
  const toggleTheme = () => {
    const theme = isDarkMode ? "light" : "dark";
    document.documentElement.setAttribute("data-theme", theme);
    localStorage.setItem("theme", theme);
    setIsDarkMode(!isDarkMode);
  };

  return (
    <nav className="bg-card dark:bg-background border-b border-gray-200 w-full fixed top-0 left-0 z-50">
      <div className="max-w-screen-xl flex flex-wrap items-center justify-between mx-auto p-1">
        <div className="flex flex-row items-center justify-center gap-2 ">
          {/* Hamburger button for mobile menu */}
          <button
            data-collapse-toggle="navbar-user"
            type="button"
            className="lg:hidden inline-flex items-center mt-1 p-1 w-8 h-8 justify-center text-sm text-gray-500 rounded-lg  hover:bg-gray-100 focus:outline-none dark:text-gray-400 dark:hover:bg-gray-700"
            aria-controls="navbar-user"
            aria-expanded={isMobileMenuOpen}
            onClick={toggleSidebar}
          >
            <span className="sr-only">Open main menu</span>
            <IoReorderThreeOutline className="text-2xl" />
          </button>
          <h2 className="self-center text-xl font-medium whitespace-nowrap dark:text-white">
            {title}
          </h2>
        </div>

        <div className="flex flex-row gap-2 md:gap-6 lg:gap-8 items-center justify-center">
          <div>
            <button
              onClick={toggleTheme}
              className="p-2 text-sm transition duration-300 bg-gray-300 dark:bg-gray-500 rounded-md text-gray-800 dark:text-gray-100"
            >
              {isDarkMode ? <IoSunny /> : <FaMoon />}
            </button>
          </div>
          <div>
            <MdOutlineNotificationsActive className="text-2xl text-text " />
          </div>

          <div className="flex flex-row items-center justify-center">
            <div className="bg-background dark:bg-card p-1 rounded-l dark:text-customBg">
              <IoWalletOutline className="text-xl " />
            </div>
            <div className="bg-background rounded-r">
              <h4 className="text-base font-normal px-2">{user?.amount}</h4>
            </div>
          </div>

          <div className="flex items-center md:order-2 space-x-3 md:space-x-0 ">
            <button
              type="button"
              className="flex text-2xl text-text rounded-full md:me-0  "
              id="user-menu-button"
              aria-expanded={isDropdownOpen}
              onClick={toggleDropdown}
            >
              <LuUserCircle2 />
            </button>
            {/* Dropdown menu */}
            {isDropdownOpen && (
              <div
                ref={dropdownRef}
                className="z-50 my-4 text-base list-none bg-background divide-y divide-gray-100 rounded-lg shadow  dark:divide-gray-600 absolute right-3 top-5" // Position dropdown
                id="user-dropdown"
              >
                <div className="px-4 py-3">
                  <span className="block text-sm text-text ">
                    {user.userName}
                  </span>
                  <span className="block text-sm text-text truncate ">
                    {user.email}
                  </span>
                </div>
                <ul className="py-2" aria-labelledby="user-menu-button">
                  <li>
                    <Link
                      to="#"
                      className="block px-4 py-2 text-sm text-text hover:bg-gray-300 "
                    >
                      {profileItem.dashboard}
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="#"
                      className="block px-4 py-2 text-sm text-text hover:bg-gray-300 "
                    >
                      {profileItem.settings}
                    </Link>
                  </li>

                  <li>
                    <Link
                      to="#"
                      className="block px-4 py-2 text-sm text-text hover:bg-gray-300 "
                    >
                      Sign out
                    </Link>
                  </li>
                </ul>
              </div>
            )}
          </div>
        </div>
      </div>
    </nav>
  );
};

export default Header;
