import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css"; // Import styles

export const Loader = () => (
  <div className="flex justify-center items-center h-screen">
    <div
      className="w-16 h-16 border-4 border-blue-500 border-t-transparent rounded-full animate-spin"
      role="status"
    >
      <span className="sr-only">Loading...</span>
    </div>
  </div>
);

export const showToast = (type, msg) => {
  const options = {
    position: "top-right", 
        autoClose: 3000, // Duration for the toast (can be customized)
        theme:"colored"

  };

  switch (type) {
    case "SUCCESS":
      toast.success(msg, options);
      break;
    case "ERROR":
      toast.error(msg, options);
      break;
    case "INFO":
      toast.info(msg, options);
      break;
    case "WARNING":
      toast.warn(msg, options);
      break;
    default:
      console.warn("Toast type not recognized");
      break;
  }
};

export const getTokenCookie = () => localStorage.getItem("token");
export const removeTokenCookie = () => localStorage.removeItem("token");
export const getAuthHeader = () => ({
  headers: { Authorization: `Bearer ${getTokenCookie()}` },
});
export const getPostAuthHeader = () => ({
  headers: { Authorization: `Bearer ${getTokenCookie()}` },
});


export const ApiUrl = "http://localhost:4002";
