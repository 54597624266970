import React from "react";
import { TiExportOutline } from "react-icons/ti";

const Export = () => {
  return (
    <>
      <div className="w-full lg:w-fit">
        <button 
        // className="flex items-center gap-2 justify-center w-full sm:w-auto bg-teal-500 hover:bg-teal-600 text-white font-medium rounded-lg text-sm px-4 py-2 transition-transform transform hover:scale-105"
        className="flex items-center gap-2 justify-center w-full sm:w-auto  hover:bg-teal-600 text-teal-500 hover:text-white border-[1px] border-teal-500 font-medium rounded-lg text-sm px-4 py-1 transition-transform transform hover:scale-105"

        >
          <TiExportOutline /> Export
        </button>
      </div>
    </>
  );
};

export default Export;
