import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import AdminUsersTable from "../../../components/table/AdminUsersTable";
import SearchBar from "../../../components/searchOrButton/SearchBar";
import FilterButton from "../../../components/searchOrButton/FilterButton";
import Export from "../../../components/searchOrButton/ExportButton";
import AddNewButton from "../../../components/searchOrButton/AddNewButton";
import { adminGetAllUsers } from "../../../store/actions/users";

const Users = () => {
  const [activeTab, setActiveTab] = useState("all");
  const [filterQuery, setFilterQuery] = useState({});
  const [currentUser, setCurrentUser] = useState({
    setting: { allregistration: false },
  });

  // Redux logic 
  const dispatch = useDispatch();
  const { userList, loading, totalCount } = useSelector(
    (state) => state.userManagement
  );
  // console.log("userList", userList);

  useEffect(() => {
    // Call the adminGetAllUsers API when the component loads
    dispatch(adminGetAllUsers(filterQuery));
  }, [dispatch, filterQuery]);


  const handleTabClick = (tab) => {
    setActiveTab(tab);
    setFilterQuery((prevQuery) => ({
      ...prevQuery,
      status: tab === "active" ? true : tab === "inactive" ? false : undefined,
    }));
  };

  const handleToggle = () => {
    setCurrentUser((prevUser) => ({
      ...prevUser,
      setting: {
        ...prevUser.setting,
        allregistration: !prevUser.setting.allregistration,
      },
    }));
  };

  // const userProducts = [
  //   {
  //     name: 'Rishi"',
  //     email: "rishi@gmail.com",
  //     phone: "8077044335",
  //     roleId: "user",
  //     status: true,
  //   },
  // ];

  const userHeader = [
    { key: "Name" },
    { key: "Email" },
    { key: "Phone" },
    { key: "Role Id" },
    { key: "Status" },
    { key: "Action" },
  ];

  return (
    <>
      <div className="flex flex-col relative">
        {/* Header Section */}
        <div className="fixed w-full lg:max-w-[calc(100%-16rem)] mt-2 md:mt-1 lg:mt-0 bg-card mx-auto p-2 md:p-3 lg:p-1 border border-teal-500 rounded-lg flex flex-col sm:flex-row items-center gap-3 md:gap-4 lg:gap-2  md:justify-start lg:justify-between z-10">
          <SearchBar />

          <div className="flex flex-col sm:flex-row items-center gap-2 sm:gap-3 lg:gap-4 w-full lg:w-auto">
            <FilterButton />
            <Export />
            <AddNewButton />
          </div>
        </div>

        <div className="lg:mt-12 md:mt-12 mt-48 relative">
          {/* Tab and Toggle Section */}
          <div className="fixed lg:max-w-[calc(100%-16rem)]  flex flex-row justify-between items-center gap-6 w-full text-center bg-card p-1  shadow-md z-20">
            {/* Title */}
            <h2 className="text-base mx-1 font-medium text-text">User</h2>

            {/* Toggle Switch */}
            <label className="inline-flex items-center cursor-pointer">
              <input
                type="checkbox"
                className="sr-only peer"
                checked={currentUser?.setting?.allregistration}
                onChange={handleToggle}
              />
              <div className="relative w-11 h-5 bg-gray-200 rounded-full dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:bg-blue-600 after:content-[''] after:absolute after:top-0.5 after:left-0.5 after:bg-white after:border-gray-300 after:border after:rounded-full after:h-4 after:w-4 after:transition-all dark:border-gray-600" />
              <span className="ml-3 text-sm font-medium text-text">
                External User
              </span>
            </label>

            {/* Tabs */}
            <ul
              className="flex flex-wrap -mb-px text-sm font-medium text-text"
              role="tablist"
            >
              {["all", "active", "inactive"].map((tab) => (
                <li className="mr-2" role="presentation" key={tab}>
                  <button
                    className={`px-4 py-2 border-b-2 ${
                      activeTab === tab
                        ? "border-blue-600 text-blue-600"
                        : "border-transparent hover:text-blue-600"
                    }`}
                    id={tab}
                    type="button"
                    onClick={() => handleTabClick(tab)}
                    role="tab"
                    aria-selected={activeTab === tab}
                  >
                    {tab.charAt(0).toUpperCase() + tab.slice(1)}
                  </button>
                </li>
              ))}
            </ul>
          </div>
         {/* User Table */}
         <div className="mt-12">
            <AdminUsersTable
              userHeader={userHeader}
              userProducts={userList} // Use data from Redux store
              loading={loading} // Pass loading state for UI feedback
              totalCount={totalCount} // Optional: for pagination or showing total users
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default Users;
