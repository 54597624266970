import React, { useState } from "react";
import Header from "./header/Header";
import Sidebar from "./sidebar/Sidebar";
import { FaHome, FaCog } from "react-icons/fa";
import { Outlet } from "react-router-dom";

const Layout = () => {
  const [isSidebarOpen, setSidebarOpen] = useState(false);

  // Header and sidebar data
  const orgName = "User Management";
  const user = { amount: "1000", userName: "user", email: "user@gmail.com" };
  const profileItem = { dashboard: "Dashboard", settings: "Settings" };
  const sidebarItem = [
    { id: 1, logo: <FaHome />, name: "Dashboard", link: "" },
    { id: 2, logo: <FaCog />, name: "Settings", link: "userSetting" },
  ];

  //   mobile view in select sidebar item then after hide sidebar...
  const closeSidebar = () => {
    setSidebarOpen(false);
  };

  return (
    <div>
      <Header
        title={orgName}
        user={user}
        profileItem={profileItem}
        onMobileSide={setSidebarOpen}
      />
      <div className="flex">
        <Sidebar
          sidebarItem={sidebarItem}
          sidebarOpen={isSidebarOpen}
          closeSidebar={closeSidebar}
        />
        <div className="sm:ml-64 mt-10 w-full">
          {" "}
          <main className=" border-gray-200 rounded-lg dark:border-gray-700">
            <Outlet /> {/* This renders the current route’s component */}
          </main>
        </div>
      </div>
    </div>
  );
};

export default Layout;
