import React, { useState } from "react";
import { FiEye, FiEyeOff } from "react-icons/fi";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { loginUser } from "../../store/actions/auth";
import { showToast } from "../../utills/tools";

const Login = () => {
  const [formData, setFormData] = useState({
    userName: "",
    password: "",
  });
  const [showPassword, setShowPassword] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleChange = (e) => {
    const { id, value } = e.target;
    setFormData({ ...formData, [id]: value });
  };

  const handleLogin = async (e) => {
    e.preventDefault();

    if (!formData.userName || !formData.password) {
      return showToast("WARNING", "Please fill in all fields.");
    }

    try {
      const result = await dispatch(loginUser(formData));
      if (result?.payload?.success) {
        showToast("SUCCESS" ,"Login successful!");
        navigate("/admin"); // Redirect to the appropriate page after login
        console.log("Login successful!", formData);
      } else {
        showToast("ERROR", "Please check your credentials.");
      }
    } catch (error) {
      showToast("ERROR", "Please check your credentials.");
      console.error("Login error:", error);
    }
  };

  const togglePasswordVisibility = () =>
    setShowPassword((prevState) => !prevState);

  return (
    <div className="flex justify-center items-center min-h-screen bg-gray-100 dark:bg-gray-900">
      <ToastContainer  />
      <form
        onSubmit={handleLogin}
        className="max-w-sm w-full mx-auto bg-white dark:bg-gray-800 rounded-lg shadow-lg p-6 space-y-3"
      >
        <div>
          <label
            htmlFor="userName"
            className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-200"
          >
            Email Id
          </label>
          <input
            type="email"
            id="userName"
            value={formData.userName}
            onChange={handleChange}
            placeholder="Enter your email id"
            aria-label="Email Id"
            required
            className="w-full p-2.5 text-sm rounded-lg bg-gray-50 border border-gray-300 text-gray-900 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
          />
        </div>

        <div>
          <label
            htmlFor="password"
            className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-200"
          >
            Password
          </label>
          <div className="relative">
            <input
              type={showPassword ? "text" : "password"}
              id="password"
              value={formData.password}
              onChange={handleChange}
              placeholder="Enter your password"
              aria-label="Password"
              required
              className="w-full p-2.5 text-sm rounded-lg bg-gray-50 border border-gray-300 text-gray-900 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            />
            <button
              type="button"
              onClick={togglePasswordVisibility}
              className="absolute inset-y-0 right-3 flex items-center text-gray-600 dark:text-gray-400"
              aria-label="Toggle password visibility"
            >
              {showPassword ? (
                <FiEyeOff className="w-5 h-5" />
              ) : (
                <FiEye className="w-5 h-5" />
              )}
            </button>
          </div>
        </div>

        <div className="text-end mt-0">
          <Link
            to="/forgot-password"
            className="text-sm text-blue-600 hover:text-blue-800 dark:text-blue-400 dark:hover:text-blue-500 cursor-pointer"
          >
            Forgot Password?
          </Link>
        </div>

        <button
          type="submit"
          className="w-full py-2.5 px-5 text-sm font-medium text-white bg-blue-700 rounded-lg hover:bg-blue-800 focus:outline-none focus:ring-4 focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
        >
          Login
        </button>

        <div className="text-center mt-4">
          <h3 className="text-sm text-gray-600 dark:text-gray-400">
            Don't have an account?{" "}
            <Link
              to="/register"
              className="text-blue-600 hover:text-blue-800 dark:text-blue-400 dark:hover:text-blue-500 cursor-pointer"
            >
              Register
            </Link>
          </h3>
        </div>
      </form>
    </div>
  );
};

export default Login;
