import React, { useState } from "react";
import { FaHome, FaCog } from "react-icons/fa";
import { Outlet } from "react-router-dom";
import AdminHeader from "./adminHeader/AdminHeader";
import AdminSidebar from "./adminSidebar/AdminSidebar";
import { FaUser } from "react-icons/fa";
import { FaMoneyBills, FaServicestack } from "react-icons/fa6";
import { MdPayment } from "react-icons/md";
import { IoWallet, IoLogoFirefox } from "react-icons/io5";
import { FaFirstOrder } from "react-icons/fa";
import { IoIosApps } from "react-icons/io";
import { MdMargin } from "react-icons/md";
import { BsFillLightningChargeFill } from "react-icons/bs";
import { SiSololearn } from "react-icons/si";
import { FaCreativeCommonsNd } from "react-icons/fa";
import { GiBoatPropeller } from "react-icons/gi";
import { MdCategory } from "react-icons/md";

// import AdminFooter from "./adminFooter/AdminFooter";

const AdminLayout = () => {
  const [isSidebarOpen, setSidebarOpen] = useState(false);

  // Header and sidebar data
  const orgName = "Admin";
  const user = { amount: "1000", userName: "Admin", email: "admin@gmail.com" };
  const profileItem = { dashboard: "Dashboard", settings: "Settings" };
  const sidebarItem = [
    { id: 1, logo: <FaHome />, name: "Dashboard", link: "" },
    { id: 2, logo: <FaUser />, name: "Users", link: "users" },
    {
      id: 3,
      logo: <FaMoneyBills />,
      name: "Recharge/Pay Bills",
      link: "recharge",
    },
    { id: 4, logo: <MdPayment />, name: "Payments", link: "payments" },
    { id: 5, logo: <IoWallet />, name: "Wallet Report", link: "walletReport" },
    {
      id: 6,
      logo: <FaFirstOrder />,
      name: "Order/TXN Report",
      link: "orderTxn",
    },
    { id: 7, logo: <IoIosApps />, name: "API Documents", link: "api" },
    {
      id: 8,
      logo: <FaCog />,
      name: "Settings",
      link: "margin",
      dropdown: [
        { id: 9, logo: <MdMargin />, name: "Margin Setting", link: "margin" },
        {
          id: 10,
          logo: <BsFillLightningChargeFill />,
          name: "Charges Setting",
          link: "charges",
        },
        {
          id: 11,
          logo: <FaServicestack />,
          name: "Service List",
          link: "service",
        },
        { id: 12, logo: <GiBoatPropeller />, name: "Provider List", link: "provider" },
        { id: 13, logo: <MdCategory />, name: "Category", link: "category" },
      ],
    },
    { id: 14, logo: <SiSololearn />, name: "Earning Reports", link: "earning" },
    {
      id: 15,
      logo: <FaCreativeCommonsNd />,
      name: "Complaints",
      link: "complaints",
    },
    { id: 16, logo: <IoLogoFirefox />, name: "Logs", link: "logs" },
  ];

  //   mobile view in select sidebar item then after hide sidebar...
  const closeSidebar = () => {
    setSidebarOpen(false);
  };

  return (
    <div>
      <AdminHeader
        title={orgName}
        user={user}
        profileItem={profileItem}
        onMobileSide={setSidebarOpen}
      />
      <div className="flex">
        <AdminSidebar
          sidebarItem={sidebarItem}
          sidebarOpen={isSidebarOpen}
          closeSidebar={closeSidebar}
        />
        <div className="sm:ml-64 mt-10 w-full">
          {" "}
          <main className=" border-gray-200 rounded-lg dark:border-gray-700">
            <Outlet /> {/* This renders the current route’s component */}
          </main>
          {/* <div>
            <AdminFooter />
          </div> */}
        </div>
      </div>
    </div>
  );
};

export default AdminLayout;
