import { createSlice } from "@reduxjs/toolkit";
import { registerUser, loginUser, logout } from "../actions/auth";

const initialState = {
  loading: false,
  data: {
    id: "",
    first_name: "",
    last_name: "",
    email: "",
    mobile_no: "",
    apiKey: "",
    wallet: {},
    extWallet: 0,
    setting: {},
  },
  token: null,
  auth: false,
  isAdmin: false,
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      // Register User
      .addCase(registerUser.pending, (state) => {
        state.loading = true;
      })
      .addCase(registerUser.fulfilled, (state) => {
        state.loading = false;
      })
      .addCase(registerUser.rejected, (state) => {
        state.loading = false;
      })

      // Login User
      .addCase(loginUser.pending, (state) => {
        state.loading = true;
      })
      .addCase(loginUser.fulfilled, (state, action) => {
        state.loading = false;
        const { user, token } = action.payload;
        state.data = {
          id: user._id,
          first_name: user.first_name,
          last_name: user.last_name,
          email: user.email,
          mobile_no: user.mobile_no,
          apiKey: user.apiKey,
          role: user.roleId,
        };
        state.auth = true;
        state.isAdmin = parseInt(user.roleId, 10) === 0;
        state.token = token;

        // Store token and user data in localStorage
        localStorage.setItem("token", token);
        localStorage.setItem("user", JSON.stringify(user));
      })
      .addCase(loginUser.rejected, (state) => {
        state.loading = false;
        state.auth = false;
      })

      // Logout User
      .addCase(logout.fulfilled, (state) => {
        state.data = initialState.data;
        state.auth = false;
        state.isAdmin = false;
        state.token = null;
        localStorage.removeItem("token");
        localStorage.removeItem("user");
      });
  },
});

export default authSlice.reducer;
