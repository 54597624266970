import React, { useState } from "react";
import { IoMdAdd } from "react-icons/io";
import { IoClose, IoEyeOff, IoEye } from "react-icons/io5";

const AddNewButton = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };
  const togglePasswordVisibility = () => setShowPassword(!showPassword);

  return (
    <>
      <div className="w-full lg:w-fit">
        <button
          onClick={toggleModal}
          // className="flex items-center gap-2 justify-center w-full sm:w-auto bg-blue-500 hover:bg-blue-700 text-white font-medium rounded-lg text-sm px-4 py-2 transition-transform transform hover:scale-105"
          className="flex items-center gap-2 justify-center w-full sm:w-auto  hover:bg-blue-500 text-blue-500 hover:text-white border-[1px] border-blue-500 font-medium rounded-lg text-sm px-4 py-1 transition-transform transform hover:scale-105"
        >
          <IoMdAdd /> Add New
        </button>
        {/* Modal */}
        {isModalOpen && (
          <div
            id="crud-modal"
            className="fixed inset-0 z-50 flex items-center justify-center overflow-y-auto overflow-x-hidden bg-gray-900 bg-opacity-50"
          >
            <div className="relative p-4 w-full max-w-md h-auto">
              {/* Modal Content */}
              <div className="relative bg-card rounded-lg shadow ">
                {/* Modal Header */}
                <div className="flex items-center justify-between p-4 md:p-5 border-b rounded-t dark:border-gray-600">
                  <h3 className="text-lg font-semibold text-text">
                    Add New User
                  </h3>
                  <button
                    type="button"
                    className="text-text bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center dark:hover:bg-gray-600 "
                    onClick={toggleModal}
                  >
                    <IoClose className="w-5 h-5" />
                  </button>
                </div>

                {/* Modal Body */}
                {/* onSubmit={handleSaveUser} */}
                <form className="p-4 md:p-5">
                  <div className="grid gap-4 mb-4 grid-cols-1">
                    <div>
                      <label
                        htmlFor="name"
                        className="block mb-2 text-sm font-medium text-text"
                      >
                        Name
                      </label>
                      <input
                        type="text"
                        id="name"
                        name="name"
                        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                        placeholder="Enter your name"
                        required
                      />
                    </div>

                    <div>
                      <label
                        htmlFor="email"
                        className="block mb-2 text-sm font-medium text-text"
                      >
                        Email
                      </label>
                      <input
                        type="email"
                        id="email"
                        name="email"
                        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                        placeholder="Enter your email"
                        required
                      />
                    </div>

                    <div>
                      <label
                        htmlFor="password"
                        className="block mb-2 text-sm font-medium text-text"
                      >
                        Password
                      </label>
                      <div className="relative">
                        <input
                          type={showPassword ? "text" : "password"}
                          id="password"
                          name="password"
                          className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                          placeholder="Enter your password"
                          required
                        />
                        <button
                          type="button"
                          onClick={togglePasswordVisibility}
                          className="absolute inset-y-0 right-0 flex items-center pr-3"
                        >
                          {showPassword ? (
                            <IoEye className="w-5 h-5 text-gray-500" />
                          ) : (
                            <IoEyeOff className="w-5 h-5 text-gray-500" />
                          )}
                        </button>
                      </div>
                    </div>

                    <div>
                      <label
                        htmlFor="role"
                        className="block mb-2 text-sm font-medium text-text"
                      >
                        Role
                      </label>
                      <select
                        id="role"
                        name="role"
                        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                        required
                      >
                        <option value="" disabled selected>
                          Select role
                        </option>
                        {/* <option value="admin">Admin</option> */}
                        <option value="user">User</option>
                      </select>
                    </div>
                  </div>

                  <button
                    type="submit"
                    className="w-full justify-center text-white inline-flex items-center bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                  >
                    Save
                  </button>
                </form>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default AddNewButton;
